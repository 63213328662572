import React, { useState } from 'react';
import { useAddSuperFeedbackMutation } from '../superCapstoneSlice';

interface FeedbackQuestionProps {
  index: number;
  question: string;
  options: string[];
  addResponse: (response: string, idx: number) => void;
}

const FeedbackQuestion: React.FC<FeedbackQuestionProps> = ({
  index,
  question,
  options,
  addResponse,
}) => {
  return (
    <div className='flex flex-col gap-7 p-5 border-2 border-gray-200 rounded-lg'>
      <span className='font-medium'>
        {index}. {question}
      </span>
      <div className='flex gap-5'>
        {options.map((option, idx) => (
          <div key={idx} className='flex flex-row gap-2 items-center'>
            <input
              type='radio'
              className='w-5 h-5'
              name={`${index}`}
              id={`${index}_${idx}`}
              onClick={() => {
                // Since index was incremented earlier
                addResponse(option, index - 1);
              }}
            />
            <label htmlFor={`${index}_${idx}`}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

type FeedbackResponseType = {
  question: string;
  response: string;
};

const FeedBackView = ({ enrollmentId, setShowFeedback }: any) => {
  const [addFeedback] = useAddSuperFeedbackMutation();
  const questions = [
    'To what extent did the project go beyond surface-level information and provide a deeper understanding of the topic?',
    'How would you rate the accuracy and sufficiency of the resources presented in addition to task explanations?',
    'How well was the project presented with clear and effective visual aids, such as graphs, charts, and tables?',
    'Question with different options',
    'How up-to-date and relevant was the information to current industry trends and developments?',
  ];

  const options = ['Excellent', 'Good', 'Average', 'Poor', 'Very Poor'];

  let initialResArray: FeedbackResponseType[] = [];
  for (const question of questions) {
    initialResArray.push({
      question: question,
      response: '',
    });
  }

  const [feedbackResponse, setFeedbackRespose] =
    useState<FeedbackResponseType[]>(initialResArray);

  const [allAnswered, setAllAnswered] = useState(false);

  const checkIfAllAnswered = (): boolean => {
    for (const res of feedbackResponse) {
      if (res.response === '') {
        return false;
      }
    }
    return true;
  };

  return (
    <div className='h-full md:border-t-2 text-justify'>
      <div className='flex flex-col gap-5 py-8 px-6'>
        <span className='font-medium text-2xl text-textColor-main'>
          Feedback and Certificate
        </span>
        <span className='p-6 bg-gray-100 rounded'>
          Please complete a short feedback form to download your certificate
        </span>
      </div>
      <div className='md:border-l-2 border-gray-200 flex flex-col h-full gap-6 justify-between'>
        <div className='px-6 py-4 flex flex-col gap-2 overflow-y-auto scrollbar md:max-h-[75vh]'>
          {questions.map((question, idx) => (
            <FeedbackQuestion
              index={idx + 1}
              options={options}
              question={question}
              key={idx}
              addResponse={(response: string, idx: number) => {
                let tempArr = feedbackResponse;
                tempArr[idx].response = response;
                setFeedbackRespose(tempArr);
                setAllAnswered(checkIfAllAnswered());
              }}
            />
          ))}
        </div>
        <div className='border-t-2 border-gray-200 py-5 px-6 flex justify-end'>
          <button
            onClick={() => {
              addFeedback({
                feedback: feedbackResponse,
                enrollId: enrollmentId,
              })
                .unwrap()
                .then((res) => {
                  setShowFeedback(false);
                });
            }}
            className={`text-white text-lg px-2 md:px-5 py-2 rounded-lg select-none ${
              allAnswered
                ? 'bg-primary pointer-events-auto'
                : 'bg-stone-500 pointer-events-none'
            }`}>
            {false ? 'Loading' : 'Submit Feedback'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedBackView;
