import React, { useEffect, useState } from 'react';
import CenturyLabHeader from './layout/CenturyLabHeader';
import Labview from './lab-view/index';
import LabDiscussions from './layout/LabDiscussions';
import QuickTipsSection from './layout/QuickTipsSection';
import SkillText from './layout/SkillText';
import LabCard from './layout/LabCard';
// import { CenturyLabType } from '../types';
// import { useGetUserProjectQuery } from '../userBatchSlice';

const CenturyLabPanel = ({ centuryData }: any) => {
  const [isLabView, setIsLabView] = useState<any>(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isLabView]);

  return (
    <div className='md:border rounded-b-lg text-textColor-lighter'>
      <CenturyLabHeader level={isLabView?.level} />
      {isLabView ? (
        <Labview setIsLabView={setIsLabView} projectDetails={isLabView} />
      ) : (
        <div className='px-4 md:px-8 space-y-14'>
          <LabDiscussions />
          <QuickTipsSection />
          <SkillText />
          <LabCard setIsLabView={setIsLabView} centuryData={centuryData} />
        </div>
      )}
    </div>
  );
};

export default CenturyLabPanel;
