import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetSuperCapstoneQuery } from 'components/superCapstone/superCapstoneSlice';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

const SuperCapstoneBreadcurmb = ({ superCapstoneId }: any) => {
  const { taskno } = useParams();
  const { data } = useGetSuperCapstoneQuery({ superCapstoneId });

  const capstoneTitle = (data && data[0]?.title) || '';
  const capstoneCompany = (data && data[0]?.companyId?.name) || '';
  const titleText = taskno
    ? `${capstoneCompany}: ${capstoneTitle.split(' ')[0]} ...`
    : `${capstoneCompany}: ${capstoneTitle}`;
  return (
    <div className='flex flex-wrap sm:flex-nowrap'>
      {capstoneTitle && (
        <li>
          <div className='flex items-center  '>
            <ChevronRightIcon
              className='h-5 w-5 flex-shrink-0 pt-1 font-medium'
              aria-hidden='true'
            />
            <Link
              to={`/supercapstone/${superCapstoneId}`}
              className={
                taskno
                  ? 'pl-4 text-xl hover:text-gray-700  '
                  : 'pl-4 text-xl font-medium hover:text-gray-700'
              }>
              <p className='lg:w-60 truncate'> {titleText}</p>
            </Link>
          </div>
        </li>
      )}
      {taskno && capstoneTitle && (
        <li>
          <div className='flex items-center'>
            <ChevronRightIcon
              className='h-5 w-5 flex-shrink-0 pt-1 font-medium'
              aria-hidden='true'
            />
            <div className='pl-4 text-xl font-medium hover:text-gray-700'>
              {taskno === 'feedback' ? 'feedback' : `Task ${taskno}`}
            </div>
          </div>
        </li>
      )}
    </div>
  );
};

export default SuperCapstoneBreadcurmb;
