import { differenceInHours } from 'date-fns';
import {
  AboutDataType,
  CompanyConnectType,
  ProjectTaskType,
  CenturyLabType,
  CoachConnectType,
  AboutCoachDataType,
  MasterclassConnectType,
  QueryTabType,
} from './types';

export const getBannerData = (batchData: any) => {
  return {
    programTitle: batchData.programTitle,
    companyName: batchData.users[0].company.name,
    companyLogo: batchData.users[0].company.logoUrl,
    companyCountry: batchData.users[0].company.country,
    domain: batchData.users[0].area.title,
    guidebook: batchData.guidebook,
    batchName: batchData.name,
    tutorial: batchData.tutorial,
    company2Name: batchData.users[0].company2?.name,
    company2Logo: batchData.users[0].company2?.logoUrl,
    company2Country: batchData.users[0].company2?.country,
    companyWebsite:
      batchData.users[0].company2?.website ||
      batchData.users[0].company?.website,
    totalWeek: batchData.week,
  };
};

export const getTabsData = (batchData: any) => {
  return {
    centuryLab: batchData.vgaTab.centuryLab,
    coachSupport: batchData.vgaTab.coachSupport,
    companyConnect: batchData.vgaTab.companyConnect,
    domainQuiz: batchData.vgaTab.domainQuiz,
    masterclass: batchData.vgaTab.masterclass,
    projectTask: batchData.vgaTab.projectTask,
    enableQueryManagement: batchData.enableQueryManagement,
  };
};

export const getCompanyAboutData: (value: any) => AboutDataType = (
  companyData
) => {
  return {
    companyName: companyData?.name,
    companyCountry: companyData?.country,
    companyWebsite: companyData?.website,
    companyContent: companyData?.sectionContent,
    industryId: companyData?.industryId,
  };
};

export const getMentorData = (mentorData: any) => {
  return {
    mentorName: mentorData?.name,
    mentorDesignation: mentorData?.designation,
    mentorImage: mentorData?.imageUrl,
    mentorLinkedin: mentorData?.linkedIn,
    mentorContent: mentorData?.sectionContent,
  };
};

const getEvents = (
  allEvents: any,
  type: string,
  userId: any,
  userEmail: string
) => {
  const filteredEvents = allEvents.filter((event: any) => {
    return event.eventId.type === type;
  });

  return filteredEvents
    .map((event: any) => {
      const pollingUser = event.eventId?.pollingUser || [];
      const isUserRegistered = pollingUser.filter((user: any) => {
        return user.userId === userId;
      });
      const isAlreadyRegistered = isUserRegistered.length ? true : false;
      const attendance = event.eventId.attendance.filter((user: any) => {
        return user.email === userEmail && user.status === 1;
      }).length;
      const feedbacks = event.eventId?.feedbacks || [];
      const isFeedbackAdded = feedbacks.filter((user: any) => {
        return user.userId === userId;
      }).length;
      const isFeedbackRequired = !!(attendance && !isFeedbackAdded);
      return {
        id: event.eventId._id,
        imageUrl: event.eventId.imageUrl,
        dateTime: event.eventId.dateTime,
        title: event.eventId.title,
        description: event.eventId.description,
        resources: event.eventId.resources,
        link: event.eventId.link,
        recordingUrl: event.eventId?.recordingUrl,
        pollingEvent: event.eventId.pollingEvent || false,
        isAlreadyRegistered,
        attendance,
        isFeedbackRequired,
      };
    })
    .sort((a: any, b: any) => {
      const dateA: any = new Date(a.dateTime);
      const dateB: any = new Date(b.dateTime);
      return dateA - dateB;
    });
};

export const getCompanyConnectData: (
  userData: any,
  events: any,
  userId: any,
  userEmail: string
) => CompanyConnectType = (userData, events, userId, userEmail) => {
  return {
    aboutData: getCompanyAboutData(userData.company),
    mentorData: getMentorData(userData.mentor),
    company2Data: userData.company
      ? getCompanyAboutData(userData.company2)
      : null,
    mentor2Data: userData.mentor2 ? getMentorData(userData.mentor2) : null,
    eventData: getEvents(events, 'Mentor Connect', userId, userEmail),
  };
};

const getProjects = (projects: any, type: string) => {
  const filteredProject = projects.filter((project: any) => {
    return project.projectId && project.projectId.type === type;
  })[0];
  const compannyProjectData = filteredProject?.projectId;
  return compannyProjectData;
};

export const getProjectTaskData: (data: any) => ProjectTaskType = ({
  userData,
  projects,
}) => {
  return {
    aboutCompanyData: getCompanyAboutData(userData.company),
    projectData: getProjects(projects, 'Company Tasks'),
  };
};

const getCenturyProjects = (projects: [], type: string) => {
  const filteredProject = projects.filter((project: any) => {
    return project.projectId && project.projectId.type === type;
  });
  const centuryProjectData = filteredProject.map((project: any) => {
    return project.projectId;
  });
  return centuryProjectData;
};

export const getCenturyLabData: (data: any) => CenturyLabType = ({
  projects,
}) => {
  return {
    centuryData: getCenturyProjects(projects, '21st Century Skills'),
  };
};

export const getMasterclassConnectData: (
  userData: any,
  events: any,
  userId: any,
  userEmail: string
) => MasterclassConnectType = (userData, events, userId, userEmail) => {
  return {
    eventData: getEvents(events, 'Masterclass', userId, userEmail),
  };
};

export const getCoachConnectData: (
  userData: any,
  events: any,
  userId: any,
  userEmail: string
) => CoachConnectType = (userData, events, userId, userEmail) => {
  return {
    aboutData: getCoachAboutData(userData.coach),
    eventData: getEvents(events, 'Coach Connect', userId, userEmail),
  };
};
export const getCoachAboutData: (value: any) => AboutCoachDataType = (
  coachData
) => {
  return {
    coachName: coachData.name,
    coachDesignation: coachData.designation,
    coachImage: coachData.coachImageUrl,
    coachLinkedin: coachData.linkedIn,
    coachExpertiseArea: coachData.areas,
    coachContent: coachData.sectionContent,
  };
};

export const getQueryTabData: (value: any) => QueryTabType = (batchData) => {
  const userData = batchData.users[0];
  return {
    batchId: batchData._id,
    mentorId: userData.mentor?._id,
    coachId: userData.coach._id,
  };
};

export const isEventLive: (eventDateTime: string) => boolean = (
  eventDateTime
) => {
  //Returns difference in hours between current time and event time using date-fns library
  const diffInHours = differenceInHours(new Date(eventDateTime), new Date());
  return diffInHours <= 1 && diffInHours >= -3;
};
